// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getAllMentorList = createAsyncThunk(
  'userMentor/getAllMentorList',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-mentor/mentorsList/all',
      {
        params
      }
    )
    return {
      params: response.data.count,
      mentorsList: response.data.data
    }
  }
)
export const getAllMentor = createAsyncThunk(
  'userMentor/getAllMentor',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-mentor/mentorsList/all',
      {
        params
      }
    )
    return {
      params: response.data.params,
      mentorsListData: response.data.data
    }
  }
)

export const getMentorsByUserId = createAsyncThunk(
  'userMentor/getMentorsByUserId',
  async ({ id }) => {
    const response = await axios.get(baseUrl() + '/user-mentor/' + id)
    return {
      mentorsListByUserId: response.data.data
    }
  }
)

export const getAllUserMentorList = createAsyncThunk(
  'userMentor/getAllUserMentorList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-mentor', { params })
    return {
      totalPages: response?.data?.count?.total,
      params: response.data.count,
      userMentorList: response.data.data
    }
  }
)

export const addMentorToUser = createAsyncThunk(
  'userMentor/addMentorToUser',
  async ({ mentorId, userId, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/user-mentor', { mentorId, userId })
      toast.success('Assigned Mentors to User successfully')
      await dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize,
          name: 'MENTOR'
        })
      )
      //return assignTopic
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateMentorForUser = createAsyncThunk(
  'userMentor/updateMentorForUser',
  async (
    { mentorId, userId, pageNo, pageSize, search },
    { dispatch, getState }
  ) => {
    try {
      await axios.put(baseUrl() + '/user-mentor', { mentorId, userId })
      toast.success('Update Mentors to User successfully')
      await dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize,
          search
        })
      )
      return true
    } catch (error) {
      return false
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteUserMentor = createAsyncThunk(
  'userMentor/deleteUserMentor',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.delete(baseUrl() + '/user-mentor/' + id)
      toast.success('Deleted successfully')
      await dispatch(
        getAllUserMentorList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserMentorSlice = createSlice({
  name: 'userMentor',
  initialState: {
    mentorsList: [],
    userMentorList: [],
    mentorsListByUserId: [],
    mentorsListData: [],
    loader: true,
    totalPages: 0,
    selected: null,
    params: {}
  },
  reducers: {
    selectUserMentor: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserMentorList.fulfilled, (state, action) => {
        state.userMentorList = action.payload.userMentorList
        state.totalPages = action.payload.totalPages
        state.params = action.payload.params
        state.loader = false
      })
      .addCase(getAllMentorList.fulfilled, (state, action) => {
        state.mentorsList = action.payload.mentorsList
        state.loader = false
      })
      .addCase(getMentorsByUserId.fulfilled, (state, action) => {
        state.mentorsListByUserId = action.payload.mentorsListByUserId
      })
      .addCase(getAllMentor.fulfilled, (state, action) => {
        state.mentorsListData = action.payload.mentorsListData
      })
  }
})

export const { selectUserMentor, setLoader } = UserMentorSlice.actions

export default UserMentorSlice.reducer
