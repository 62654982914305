// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getUserList = createAsyncThunk(
  'user/getUserList',
  async (params) => {
    let { url, ...rest } = params
    const response = await axios.get(baseUrl() + '/company', { params })
    console.log("response", response)
    return {
      // totalPages: response.data.count.total,  // 1s we receved count.total from backend for deleteUser api we need to uncomment this code and remove the below code  response.data.data.length,
      totalPages: response?.data?.count?.total, // this is just for now to make deleteUser api working as it dont have .count.total,
      params: params,
      data: response.data.data
    }
  }
)




export const getAllCountry = createAsyncThunk(
  'user/getAllCountry',
  async (params) => {
    const response = await axios.get(baseUrl() + `/locations`)
    return {
      data: response.data.result
    }
  }
)

export const getAllState = createAsyncThunk('user/getAllState', async (id) => {
  const response = await axios.get(baseUrl() + `/state?countryId=${id}`)
  return {
    data: response.data.data
  }
})

export const getAllCity = createAsyncThunk('user/getAllCity', async (id) => {
  const response = await axios.get(baseUrl() + `/city?stateId=${id}`)
  return {
    data: response.data.data
  }
})

export const getAllUserList = createAsyncThunk(
  'user/getAllUserList',
  async (id) => {
    const response = await axios.get(baseUrl() + '/user/all', { id }) // why id has been passed here for getting all data ??
    return {
      userAllList: response.data.data
    }
  }
)

export const getCompanyById = createAsyncThunk(
  'user/getCompanyById',
  async ({ companyId }, { dispatch }) => {
    dispatch(setLoader(true))
    // const userTechnologies = await axios.get(
    //   baseUrl() + `/user/${userId}/technologies`
    // )
    // const userReview = await axios.get(
    //   baseUrl() + `/user-review?userId=${userId}`
    // )  
    const userDetail = await axios.get(baseUrl() + `/company/${companyId}`)
    console.log("userDetail", userDetail)
    return {
      // userTechnologies: userTechnologies.data.data,
      companyListById: userDetail.data,
      // totalPages: userReview?.data.count.total,
      // params: userReview?.data.params,
      // userReview: userReview?.data.data,
      loader: false
    }
  }
)

export const getTechnologyByUserId = createAsyncThunk(
  'user/getTechnologyByUserId',
  async ({ userId }) => {
    const response = await axios.get(baseUrl() + `/user/${userId}/technologies`)
    return {
      data: response.data.data
    }
  }
)
export const getUserListById = createAsyncThunk(
  'user/getUserListById',

  async ({ userId }) => {
    const response = await axios.get(baseUrl() + `/user/${userId}`)

    return {
      companyListById: response.data.data
    }
  }
)

export const addMentorUser = createAsyncThunk(
  'addMentorUser',
  async (user, { dispatch, getState }) => {
    await axios.post(baseUrl() + '/user-mentor', user)
    return user
  }
)

export const getAllMentor = createAsyncThunk('getAllMentor', async (id) => {
  const response = await axios.get(baseUrl() + '/user-mentor')
  return {
    mentorAllList: response.data.data
  }
})

export const addUserReview = createAsyncThunk(
  'user/addUserReview',
  async (payload, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/user-review', payload)
      toast.success('Review Added successfully')
      dispatch(
        getUserReviewList({
          userId: payload.userId
        })
      )
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const getUserReviewList = createAsyncThunk(
  'user/getUserReviewList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/user-review', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.data,
      loader: false
    }
  }
)

export const addUser = createAsyncThunk(
  'user/addUser',
  async ({ payload, params }, { pageNo, pageSize, dispatch, getState }) => {
    console.log("payloadss", payload)
    try {
      await axios.post(baseUrl() + '/company', payload)
      toast.success('Company Added successfully')
      dispatch(getUserList({ ...params }))
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async ({ id, payload }, { dispatch }) => {
    console.log("payloadss", id, payload)

    try {
      await axios.put(baseUrl() + `/company/${id}`, payload
      ),
        toast.success('Company Updated successfully')
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const deleteUser = createAsyncThunk(
  'user/deleteUser',
  async ({ id, pageNo, pageSize }, { dispatch, getState }) => {
    try {
      await axios.delete(baseUrl() + `/company/${id}`),
        toast.success('Company Deleted successfully')
      const params = { pageNo, pageSize }
      await dispatch(getUserList({ ...params }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const user = createSlice({
  name: 'user',
  initialState: {
    totalPages: 0,
    userList: [],
    userAllList: [],
    mentorAllList: [],
    companyListById: [],
    userReviewList: [],
    locationList: [],
    stateList: [],
    cityList: [],
    selectUser: {},
    technologyListById: [],
    allDeletedUsers: [],
    showDeletedUser: false,
    reload: false,
    toaster: '',
    loading: false,
    clearFilter: false,
    loader: false,
    formLoader: false,
    params: {
      pageNo: 1,
      pageSize: 10
    }
  },
  reducers: {
    setSelectUser: (state, action) => {
      if (action.payload === null) {
        state.selectUser = null
      } else {
        state.selectUser = action.payload
      }
    },
    setLoading: (state) => {
      state.loading = !state.loading
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setFormLoader: (state, action) => {
      state.formLoader = action.payload
    },
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
    setShowDeletedUser: (state, action) => {
      state.showDeletedUser = action.payload
    }
  },

  extraReducers: {
    [addUser.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.toaster = 'User Added Successfully'
    },
    [getUserList.fulfilled]: (state, action) => {
      state.userList = action.payload.data
      state.totalPages = action.payload.totalPages
      state.params = action.payload.params
      state.loader = false
    },
    [getAllCountry.fulfilled]: (state, action) => {
      state.locationList = action.payload.data
    },
    [getAllState.fulfilled]: (state, action) => {
      state.stateList = action.payload.data
    },
    [getAllCity.fulfilled]: (state, action) => {
      state.cityList = action.payload.data
    },
    [getAllUserList.fulfilled]: (state, action) => {
      state.userAllList = action.payload
    },
    [getAllMentor.fulfilled]: (state, action) => {
      state.mentorAllList = action.payload
    },
    [getUserListById.fulfilled]: (state, action) => {
      state.companyListById = action.payload
    },
    [getTechnologyByUserId.fulfilled]: (state, action) => {
      state.technologyListById = action.payload.data
    },
    [getUserReviewList.fulfilled]: (state, action) => {
      state.userReviewList = action.payload.data
      state.loader = action.payload.loader
    },
    [deleteUser.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getCompanyById.fulfilled]: (state, action) => {
      state.technologyListById = action.payload.userTechnologies
      state.userReviewList = action.payload.userReview
      state.companyListById = action.payload.companyListById
      state.loader = false
    }
  }
})

export const {
  setSelectUser,
  setLoading,
  selectClearFilter,
  setLoader,
  setParams,
  setFormLoader,
  setShowDeletedUser
} = user.actions

export default user.reducer
