// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getLocationList = createAsyncThunk(
  'locations/getLocationList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/locations', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.result
    }
  }
)

export const allLocationList = createAsyncThunk(
  'locations/allLocationList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/locations', { params })
    return {
      params: response.data.params,
      allLocationList: response.data.result
    }
  }
)

export const addLocation = createAsyncThunk(
  'locations/addLocation',
  async ({ payload, params }, { dispatch, getState }) => {
    try {
      await axios.post(baseUrl() + '/locations', payload)
      toast.success('Location Added successfully')
      await dispatch(getLocationList({ params: params }))
      return true
    } catch (error) {
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const updateLocation = createAsyncThunk(
  'locations/updateLocation',
  async ({ id, name }, { dispatch, getState }) => {
    try {
      await axios.put(baseUrl() + `/locations/${id}`, {
        id,
        name
      })
      toast.success('Location Updated successfully')
      await dispatch(getLocationList())
      return true
    } catch (error) {
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const deleteLocation = createAsyncThunk(
  'locations/deleteLocation',
  async ({ id }, { dispatch, getState }) => {
    try {
      await axios.delete(baseUrl() + `/locations/${id}`),
        toast.success('Location Deleted successfully')
      await dispatch(getLocationList())
      return true
    } catch (error) {
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const locations = createSlice({
  name: 'locations',
  initialState: {
    locationList: [],
    selectFacility: [],
    allLocationList: [],
    reload: false,
    loader: false,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectLocation: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },

  extraReducers: {
    [addLocation.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getLocationList.fulfilled]: (state, action) => {
      state.locationList = action.payload.data
      state.totalPages = action.payload.totalPages
      state.loader = false
    },
    [allLocationList.fulfilled]: (state, action) => {
      state.allLocationList = action.payload.allLocationList
    },
    [updateLocation.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.loader = false
    },
  }
})

export const { selectLocation, setLoader, setRowsPerPage, setCurrentPage } = locations.actions

export default locations.reducer
