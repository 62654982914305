// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getUserTechnologyList = createAsyncThunk(
  'userTechnology/getUserTechnologyList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/usertechnologies', {
      params
    })
    return {
      totalPages: response.data.totalPages,
      params: response.data.params,
      userTechnologyList: response.data
    }
  }
)

export const addUserTechnology = createAsyncThunk(
  'userTechnology/addUserTechnology',
  async ({ pageNo, pageSize, userId, technolgoyId }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/usertechnologies', {
        userId,
        technolgoyId
      })
      toast.success('Assign Technologys to User successfully')
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }

    // return userTechnology
  }
)

export const updateUserTechnology = createAsyncThunk(
  'userTechnology/updateAssignTopic',
  async ({ userId, technolgoyId, pageNo, pageSize, search }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + `/usertechnologies`, {
        userId,
        technolgoyId
      })
      toast.success('Technologys to User Updated successfully')
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize,
          search
        })
      )
      // return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteUserTechnology = createAsyncThunk(
  'userTechnology/deleteAssignTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.delete(baseUrl() + '/usertechnologies/' + id)
      toast.success('Deleted successfully')
      await dispatch(
        getUserTechnologyList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserTechSlice = createSlice({
  name: 'userTechnology',
  initialState: {
    userTechnologyList: [],
    selected: null,
    loader: true
  },
  reducers: {
    selectUserTech: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserTechnologyList.fulfilled, (state, action) => {
        state.userTechnologyList = action.payload.userTechnologyList
        state.loader = false
      })
      .addCase(getUserTechnologyList.pending, (state) => {
        state.loader = true
      })
  }
})

export const { selectUserTech } = UserTechSlice.actions

export default UserTechSlice.reducer
