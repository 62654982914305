// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'


export const getBusinessFeaturedList = createAsyncThunk(
    'featuredList/getBusinessFeaturedList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/featured', { params })
        return {
            totalPages: response.data.result.count.total,
            params: response.data.params,
            data: response.data.result.data
        }
    }
)

export const getLocationList = createAsyncThunk('user/getLocationList', async () => {
    const response = await axios.get(baseUrl() + `/enduser/contacts`)

    return {
        data: response.data.data
    }
})

export const getClientContactList = createAsyncThunk('user/getClientContactList', async () => {
    const response = await axios.get(baseUrl() + `/company/getcontacts`)

    return {
        data: response.data.data
    }
})
export const getFeaturedLocationList = createAsyncThunk(
    'featuredList/getFeaturedLocationList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/featured/get/location', { params })
        return {
            totalPages: response.data.result.count.total,
            params: response.data.params,
            data: response.data.result.data
        }
    }
)

// export const getTextMessageList = createAsyncThunk(
//     'featuredList/getTextMessageList',
//     async (params) => {
//         const response = await axios.get(baseUrl() + '/featured/gettextmsg', { params })
//         return {
//             totalPages: response.data.totalCount,
//             params: response.data.params,
//             data: response.data.data
//         }
//     }
// )


export const addFeaturedBusiness = createAsyncThunk(
    'featuredList/addFeaturedBusiness',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/featured/add', payload)
            toast.success('Featured Business Added successfully')
            dispatch(setStatus("0"))
            await dispatch(getBusinessFeaturedList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data)
            console.log("error", error)
            return false
        }
    }
)

export const addFeaturedLocation = createAsyncThunk(
    'featuredList/addFeaturedLocation',
    async ({ payload, params }, { dispatch, getState }) => {
        console.log("notify", params, payload)
        try {
            await axios.post(baseUrl() + '/featured/add/location', payload)
            toast.success('Featured Location added successfully')
            dispatch(setStatus("1"))
            await dispatch(getFeaturedLocationList({ ...params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data)
            console.log("error", error)

            return false
        }
    }
)

export const updateFeaturedBusiness = createAsyncThunk(
    'featuredList/updateFeaturedBusiness',
    async ({ id, payload, params }, { dispatch }) => {
        try {
            await axios.put(baseUrl() + `/featured/${id}`, payload)
            toast.success('Updated successfully')
            await dispatch(getBusinessFeaturedList({ params: params }))
            return { id }
        } catch (error) {
            toast.error(error?.response?.data)
        }
    }
)

export const updateFeaturedLocation = createAsyncThunk(
    'featuredList/updateFeaturedLocation',
    async ({ id, payload, params }, { dispatch }) => {
        try {
            await axios.put(baseUrl() + `/featured/update/location/${id}`, payload)
            toast.success('Updated successfully')
            await dispatch(getFeaturedLocationList({ params: params }))
            return { id }
        } catch (error) {
            toast.error(error?.response?.data)
        }
    }
)



export const deleteFeaturedBusiness = createAsyncThunk(
    'featuredList/deleteFeaturedBusiness',
    async ({ id }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/featured/${id}`),
                toast.success('Deleted successfully')
            await dispatch(getBusinessFeaturedList())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const deleteFeaturedLocation = createAsyncThunk(
    'featuredList/deleteFeaturedLocation',
    async ({ id }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/featured/delete/location/${id}`),
                toast.success('Deleted successfully')
            await dispatch(getFeaturedLocationList())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const featuredList = createSlice({
    name: 'featuredList',
    initialState: {
        featureList: [],
        selectFeatured: [],
        featuredLocationList: [],
        locationList: [],
        clientContactList: [],
        reload: false,
        loader: false,
        rowsPerPage: 10,
        currentPage: 1,
        totalPages: 0,
        selected: null,
        status: '0',
        loading: false,
        clearFilter: false,
        selected: null,
        params: {
            pageNo: 1,
            pageSize: 10
        }

    },
    reducers: {
        selectFeatured: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        },
        selectCurrentPage: (state, action) => {
            if (action.payload === null) {
                state.currentPage = null
            } else {
                state.currentPage = action.payload
            }
        },
        selectRowsPerPage: (state, action) => {
            if (action.payload === null) {
                state.rowsPerPage = null
            } else {
                state.rowsPerPage = action.payload
            }
        },
        selectClearFilter: (state, action) => {
            state.clearFilter = action.payload
        },
        setStatus: (state, action) => {
            state.status = action.payload
        },
        setLoader: (state) => {
            state.loader = !state.loader
        },
        setLoading: (state, action) => {
            state.loading = action.payload
        }

    },

    extraReducers: {
        [addFeaturedBusiness.pending]: (state) => {
            state.loader = true
        },
        [addFeaturedBusiness.fulfilled]: (state, action) => {
            state.reload = !state.reload
            state.loader = false
        },
        [addFeaturedBusiness.rejected]: (state) => {
            state.loader = false
        },
        [getBusinessFeaturedList.pending]: (state) => {
            state.loader = true
        },
        [getBusinessFeaturedList.fulfilled]: (state, action) => {
            state.featureList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getBusinessFeaturedList.rejected]: (state) => {
            state.loader = false
        },
        [getLocationList.fulfilled]: (state, action) => {
            state.locationList = action.payload.data
        },
        [getClientContactList.fulfilled]: (state, action) => {
            state.clientContactList = action.payload.data
        },
        [getFeaturedLocationList.pending]: (state) => {
            state.loader = true
        },
        [getFeaturedLocationList.fulfilled]: (state, action) => {
            state.featureList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [getFeaturedLocationList.rejected]: (state) => {
            state.loader = false
        },

    }
})

export const { selectFeatured, selectCurrentPage,
    selectRowsPerPage,
    selectClearFilter,
    setStatus,
    setLoader,
    setLoading } = featuredList.actions

export default featuredList.reducer
