// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getFacilityList = createAsyncThunk(
  'facilities/getFacilityList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/facilities', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.result
    }
  }
)

export const allFacilityList = createAsyncThunk(
  'facilities/allFacilityList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/facilities', { params })
    return {
      params: response.data.params,
      allFacilityList: response.data.result
    }
  }
)

export const addFacility = createAsyncThunk(
  'facilities/addFacility',
  async ({ payload, params }, { dispatch, getState }) => {
    try {
      await axios.post(baseUrl() + '/facilities', payload)
      toast.success('Facility Added successfully')
      await dispatch(getFacilityList({ params: params }))
      return true
    } catch (error) {
      console.log("error", error)
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const updateFacility = createAsyncThunk(
  'facilities/updateFacility',
  async ({ id, name }, { dispatch, getState }) => {
    try {
      await axios.put(baseUrl() + `/facilities/${id}`, {
        id,
        name
      })
      toast.success('Facility Updated successfully')
      await dispatch(getFacilityList())
      return true
    } catch (error) {
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const deleteFacility = createAsyncThunk(
  'facilities/deleteFacility',
  async ({ id }, { dispatch, getState }) => {
    try {
      await axios.delete(baseUrl() + `/facilities/${id}`),
        toast.success('Facility Deleted successfully')
      await dispatch(getFacilityList())
      return true
    } catch (error) {
      toast.error(error?.response?.data?.result)
      return false
    }
  }
)

export const facilities = createSlice({
  name: 'facilities',
  initialState: {
    facilityList: [],
    selectFacility: [],
    allFacilityList: [],
    reload: false,
    loader: false,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0,
    selected: null
  },
  reducers: {
    selectFacility: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },

  extraReducers: {
    [addFacility.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getFacilityList.fulfilled]: (state, action) => {
      state.facilityList = action.payload.data
      state.totalPages = action.payload.totalPages
      state.loader = false
    },
    [allFacilityList.fulfilled]: (state, action) => {
      state.allFacilityList = action.payload.allFacilityList
    },
    [updateFacility.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.loader = false
    },
  }
})

export const { selectFacility, setLoader, setRowsPerPage, setCurrentPage } = facilities.actions

export default facilities.reducer
