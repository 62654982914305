// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getPackageList = createAsyncThunk(
  'packagePlan/getPackageList',
  async (params) => {
    let { url, ...rest } = params
    const response = await axios.get(baseUrl() + '/package?from=dashboard', { params })
    return {
      // totalPages: response.data.count.total,  // 1s we receved count.total from backend for deletePackage api we need to uncomment this code and remove the below code  response.data.data.length,
      //totalPages: response?.data?.count?.total, // this is just for now to make deletePackage api working as it dont have .count.total,
      //params: params,
      data: response.data
    }
  }
)

export const getAllPackage = createAsyncThunk(
  'packagePlan/getAllPackage',
  async (id) => {
    const response = await axios.get(baseUrl() + '/package', { id }) // why id has been passed here for getting all data ??
    return {
      packageAllList: response.data.data
    }
  }
)


export const addPackage = createAsyncThunk(
  'packagePlan/addPackage',
  async ({ payload, params }, { pageNo, pageSize, dispatch, getState }) => {
    try {
      await axios.post(baseUrl() + '/package', payload)
      toast.success('Package Added successfully')
      dispatch(getPackageList({ ...params }))
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const updatePackage = createAsyncThunk(
  'packagePlan/updatePackage',
  async ({ id, payload }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/package/${id}`, payload
      ),
        toast.success('Package Updated successfully')
      return true
    } catch (error) {
      toast.error(error?.response?.data?.message)
      return false
    }
  }
)

export const deletePackage = createAsyncThunk(
  'packagePlan/deletePackage',
  async ({ id, pageNo, pageSize }, { dispatch, getState }) => {
    try {
      await axios.delete(baseUrl() + `/package/${id}`),
        toast.success('Package Deleted successfully')
      const params = { pageNo, pageSize }
      await dispatch(getPackageList({ ...params }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const packageSlice = createSlice({
  name: 'packagePlan',
  initialState: {
    totalPages: 0,
    packageList: [],
    packageAllList: [],
    selectPackage: {},
    reload: false,
    toaster: '',
    loading: false,
    clearFilter: false,
    loader: false,
    formLoader: false,
    params: {
      pageNo: 1,
      pageSize: 10
    }
  },
  reducers: {
    setSelectPackage: (state, action) => {
      if (action.payload === null) {
        state.selectPackage = null
      } else {
        state.selectPackage = action.payload
      }
    },
    setLoading: (state) => {
      state.loading = !state.loading
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setFormLoader: (state, action) => {
      state.formLoader = action.payload
    },
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
  },

  extraReducers: {
    [addPackage.fulfilled]: (state, action) => {
      state.reload = !state.reload
      state.toaster = 'Package Added Successfully'
    },
    [getPackageList.fulfilled]: (state, action) => {
      state.packageList = action.payload.data
      //state.totalPages = action.payload.totalPages
      // state.params = action.payload.params
      state.loader = false
    },
    [getAllPackage.fulfilled]: (state, action) => {
      state.packageAllList = action.payload
    },
    [deletePackage.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
  }
})

export const {
  setSelectPackage,
  setLoading,
  selectClearFilter,
  setLoader,
  setParams,
  setFormLoader,
  setShowDeletedUser
} = packageSlice.actions

export default packageSlice.reducer
