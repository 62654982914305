// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getBulkUploadList = createAsyncThunk(
  'bulkupload/getBulkUploadList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/bulk-upload', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      bulkUploadList: response.data.data
    }
  }
)

export const getDownloadFiles = createAsyncThunk(
  'bulkupload/getDownloadFiles',
  async ({ fileName }) => {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/pdf'
      }
    }
    await axios
      .get(baseUrl() + `/downloadFile/${fileName}`, { config })
      .then((res) => {})
      .catch((error) => console.log(error))
  }
)

export const uploadUsers = createAsyncThunk(
  'bulkupload/uploadUsers',
  async ({ payload, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/bulk-upload', payload, { params })
      toast.success('Employees uploaded successfully!')
      dispatch(getBulkUploadList())
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const BulkUploadSlice = createSlice({
  name: 'bulkUpload',
  initialState: {
    bulkUploadList: [],
    selected: null,
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    loader: true
  },
  reducers: {
    selectBulkUpload: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBulkUploadList.fulfilled, (state, action) => {
        state.bulkUploadList = action.payload.bulkUploadList
        state.totalPages = action.payload.totalPages
        state.loader = false
      })
      .addCase(getBulkUploadList.pending, (state) => {
        state.loader = true
      })
  }
})

export const { selectBulkUpload, selectCurrentPage, selectRowsPerPage } =
  BulkUploadSlice.actions

export default BulkUploadSlice.reducer
