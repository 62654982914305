// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getAssignTopicList = createAsyncThunk(
  'assignTopic/getAssignTopicList',
  async (params) => {
    //
    const response = await axios.get(baseUrl() + '/user-topic', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      assignTopicList: response.data.data,
      filterParams: params,
      loader: false
    }
  }
)

export const getAllUserTopicHistory = createAsyncThunk(
  'assignTopic/getAllUserTopicHistory',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-topic-history', {
      params
    })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      allUserTopicHistory: response.data.data
    }
  }
)

export const getUserTopicHistoryById = createAsyncThunk(
  'assignTopic/getUserTopicHistoryById',
  async ({ id }) => {
    const response = await axios.get(baseUrl() + `/user-topic-history/ ${id}`)
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      userTopicHistoryById: response.data.data
    }
  }
)

export const getDownloadFiles = createAsyncThunk(
  'assignTopic/getDownloadFiles',
  async ({ fileName }) => {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        //Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        Accept: 'application/pdf'
      }
    }
    await axios
      .get(baseUrl() + `/downloadFile/${fileName}`, { config })
      .then((res) => {})
      .catch((error) => console.log(error))
  }
)

export const addUserTopicHistory = createAsyncThunk(
  'assignTopic/addUserTopicHistory',
  async (
    { comment, id, rating, status, usertopicId, pageNo, pageSize },
    { dispatch }
  ) => {
    try {
      await axios.post(baseUrl() + '/user-topic-history', {
        comment,
        id,
        rating,
        status,
        usertopicId
      })
      toast.success('Topic Assigned successfully')
      await dispatch(
        getAssignTopicList({
          pageNo,
          pageSize,
          status
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const addAssignTopic = createAsyncThunk(
  'assignTopic/addAssignTopic',
  async (
    { userId, topicId, startDate, endDate, pageNo, pageSize, status },
    { dispatch }
  ) => {
    try {
      await axios.post(baseUrl() + '/user-topic', {
        userId,
        topicId,
        startDate,
        endDate
      })
      toast.success('Topic Assigned successfully')
      await dispatch(
        getAssignTopicList({
          pageNo,
          pageSize,
          status
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateAssignTopic = createAsyncThunk(
  'assignTopic/updateAssignTopic',
  async ({ userId, id, rating, status, comment }, { dispatch }) => {
    try {
      const response = await axios.put(baseUrl() + `/user-topic/${id}`, {
        rating,
        status,
        comment,
        id
      })
      toast.success('Assigned Topics Updated successfully')
      dispatch(
        getAllUserTopicHistory({
          userId,
          userTopicId: id
        })
      )
      return response
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateUserTopicStatus = createAsyncThunk(
  'assignTopic/updateUserTopicStatus',
  async ({ id, userId, status }, { dispatch }) => {
    try {
      await axios.patch(baseUrl() + `/user-topic/${id}`, { status })
      toast.success('User Topic Updated successfully')
      await dispatch(
        getAllUserTopicHistory({
          userId
        })
      )
      return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteAssignTopic = createAsyncThunk(
  'assignTopic/deleteAssignTopic',
  async ({ id, status, pageNo, pageSize }, { dispatch }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + '/user-topic/' + id)
      toast.success('User Topic Deleted successfully')
      await dispatch(
        getAssignTopicList({
          pageNo,
          pageSize,
          status
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteFile = createAsyncThunk(
  'assignTopic/deleteFile',
  async ({ id }) => {
    try {
      await axios.delete(baseUrl() + '/deleteFiles/' + id)
      //toast.success('File Deleted successfully')
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const AssignTopicSlice = createSlice({
  name: 'assignTopic',
  initialState: {
    assignTopicList: [],
    allUserTopicHistory: [],
    userTopicHistoryById: [],
    getFiles: [],
    selected: null,
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    clearFilter: false,
    status: '0',
    loader: true,
    filterParams: {}
  },
  reducers: {
    selectAssignTopic: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    },
    selectClearFilter: (state, action) => {
      state.clearFilter = action.payload
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },

  extraReducers: (builder) => {
    builder
      .addCase(getAssignTopicList.fulfilled, (state, action) => {
        ;(state.assignTopicList = action.payload.assignTopicList),
          (state.totalPages = action.payload.totalPages)
        state.filterParams = action?.payload?.filterParams
        state.loader = action.payload.loader
      })
      .addCase(getAllUserTopicHistory.fulfilled, (state, action) => {
        ;(state.allUserTopicHistory = action.payload.allUserTopicHistory),
          (state.totalPages = action.payload.totalPages),
          (state.loader = false)
      })
      .addCase(getUserTopicHistoryById.fulfilled, (state, action) => {
        ;(state.userTopicHistoryById = action.payload.userTopicHistoryById),
          (state.totalPages = action.payload.totalPages)
      })
  }
})

export const {
  selectAssignTopic,
  selectCurrentPage,
  selectRowsPerPage,
  selectClearFilter,
  setStatus,
  setLoader
} = AssignTopicSlice.actions

export default AssignTopicSlice.reducer
