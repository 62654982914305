import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getSessionFilter = createAsyncThunk(
  'session/getSessionFilter',
  async (params) => {
    const response = await axios.get(baseUrl() + '/sessions', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      data: response.data.data
    }
  }
)

export const addSession = createAsyncThunk(
  'session/addSession',
  async (params) => {
    try {
      await axios.post(baseUrl() + '/sessions', params)
      toast.success('Session Added successfully')
      return null
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateSession = createAsyncThunk(
  'session/updateSession',
  async ({ id, payload }) => {
    try {
      await axios.put(baseUrl() + `/sessions/${id}`, {
        ...payload
      })
      toast.success('Session Updated successfully')
      return { id }
    } catch (error) {}
  }
)

export const deleteSession = createAsyncThunk(
  'session/deleteSession',
  async ({ id }, { dispatch }) => {
    try {
      await axios.delete(baseUrl() + `/sessions/${id}`),
        toast.success('Session Deleted successfully')
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const session = createSlice({
  name: 'session',
  initialState: {
    sessionList: [],
    selectSession: [],
    reload: false,
    totalPages: 0
  },
  reducers: {
    selectSession: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },

  extraReducers: {
    [addSession.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [getSessionFilter.fulfilled]: (state, action) => {
      state.sessionList = action.payload.data
      state.totalPages = action.payload.totalPages
    },
    [updateSession.fulfilled]: (state, action) => {
      state.reload = !state.reload
    },
    [deleteSession.fulfilled]: (state, action) => {
      state.reload = !state.reload
    }
  }
})

export const { selectSession } = session.actions

export default session.reducer
