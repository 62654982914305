// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getSubTopicList = createAsyncThunk(
  'subTopic/getSubTopicList',
  async (params) => {
    //dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/sub-topic', { params })
    return {
      totalPages: response.data.count,
      subTopicList: response.data.data,
      loader: false
    }
  }
)

export const addSubTopic = createAsyncThunk(
  'subTopic/addSubTopic',
  async ({ formData, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/sub-topic', formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('SubTopic Added successfully')
      await dispatch(
        getSubTopicList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateSubTopic = createAsyncThunk(
  'subTopic/updateSubTopic',
  async ({ id, updateData, pageNo, pageSize, search }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/sub-topic/${id}`, updateData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('SubTopic Updated successfully')
      await dispatch(
        getSubTopicList({
          pageNo,
          pageSize,
          search
        })
      )
      return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteSubTopic = createAsyncThunk(
  'subTopic/deleteSubTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + '/sub-topic/' + id)
      toast.success('SubTopic Deleted successfully')
      await dispatch(
        getSubTopicList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const SubTopicSlice = createSlice({
  name: 'subTopic',
  initialState: {
    subTopicList: [],
    selected: null,
    getFiles: [],
    loader: false,
    totalPages: 0
  },
  reducers: {
    selectSubTopic: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSubTopicList.fulfilled, (state, action) => {
      ;(state.subTopicList = action.payload.subTopicList),
        (state.totalPages = action.payload.totalPages)
      state.loader = action.payload.loader
    })
  }
})

export const { selectSubTopic, setLoader } = SubTopicSlice.actions

export default SubTopicSlice.reducer
