// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../app.config'
// import { baseUrl } from '../../../../../app.config'

export const getUserProjectList = createAsyncThunk(
  'userProject/getUserProjectList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-projects', {
      params
    })
    return {
      total: response?.data?.count?.total,
      userProjectsList: response.data.data,
      params: response.data.count
    }
  }
)

export const addUserProjectList = createAsyncThunk(
  'userProject/addUserProjectList',
  async ({ pageNumber, pageSize, projectId, userId }, { dispatch }) => {
    try {
      const response = await axios.post(baseUrl() + '/user-projects', {
        projectId,
        userId
      })
      toast.success(response?.data?.message)
      await dispatch(
        getUserProjectList({
          pageNumber,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }

    // return user-projects
  }
)

export const updateUserProjectList = createAsyncThunk(
  'userProject/updateUserProjectList',
  async ({ pageNumber, pageSize, projectId, userId }, { dispatch }) => {
    try {
      const response = await axios.put(baseUrl() + `/user-projects`, {
        projectId,
        userId
      })
      toast.success(response?.data?.message)
      await dispatch(
        getUserProjectList({
          pageNumber,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteUserProjectList = createAsyncThunk(
  'userProject/deleteAssignTopic',
  async ({ id, pageNumber, pageSize }, { dispatch }) => {
    try {
      const response = await axios.delete(baseUrl() + '/user-projects/' + id)
      toast.success(response?.data?.message)
      await dispatch(
        getUserProjectList({
          pageNumber,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserProjectSlice = createSlice({
  name: 'userProject',
  initialState: {
    userProjectsList: [],
    selected: null,
    loader: true,
    params: {
      pageNumber: 1,
      pageSize: 10,
      search: ''
    },
    total: 0
  },
  reducers: {
    selectUserProjects: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserProjectList.fulfilled, (state, action) => {
        state.userProjectsList = action.payload.userProjectsList
        state.params = action.payload.params
        state.total = action.payload.total
        state.loader = false
      })
      .addCase(getUserProjectList.pending, (state) => {
        state.loader = true
      })
  }
})

export const { selectUserProjects } = UserProjectSlice.actions

export default UserProjectSlice.reducer
