// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../../app.config'

export const getProjectList = createAsyncThunk(
  'project/getProjectList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/projects', {
      params
    })
    return {
      totalPages: response.data.count,
      params: response.data.params,
      userProjectsList: response.data
    }
  }
)

export const addProjects = createAsyncThunk(
  'project/addProjects',
  async (
    { pageNo, pageSize, startDate, endDate, projectManegerName, projectName },
    { dispatch }
  ) => {
    try {
      const response = await axios.post(baseUrl() + '/projects', {
        startDate,
        endDate,
        projectManegerName,
        projectName
      })
      toast.success(response?.data?.message)
      await dispatch(
        getProjectList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }

    // return projects
  }
)

export const updateProjects = createAsyncThunk(
  'project/updateProjects',
  async (
    {
      pageNo,
      pageSize,
      startDate,
      endDate,
      projectManegerName,
      projectName,
      id
    },
    { dispatch }
  ) => {
    try {
      const response = await axios.put(baseUrl() + `/projects/${id}`, {
        startDate,
        endDate,
        projectManegerName,
        projectName
      })
      toast.success(response?.data?.message)
      await dispatch(
        getProjectList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteProjects = createAsyncThunk(
  'project/deleteAssignTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      const response = await axios.delete(baseUrl() + '/projects/' + id)
      toast.success(response?.data?.message)
      await dispatch(
        getProjectList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const UserTechSlice = createSlice({
  name: 'project',
  initialState: {
    userProjectsList: [],
    selected: null,
    loader: true,
    totalPages: 0
  },
  reducers: {
    selectProjects: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectList.fulfilled, (state, action) => {
        state.userProjectsList = action.payload.userProjectsList
        state.totalPages = action.payload.totalPages
        state.loader = false
      })
      .addCase(getProjectList.pending, (state) => {
        state.loader = true
      })
  }
})

export const { selectProjects } = UserTechSlice.actions

export default UserTechSlice.reducer
