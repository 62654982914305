// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getCategoryList = createAsyncThunk(
  'mainCategory/getCategoryList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/mainCategory', { params })
    console.log("response", response.data)
    return {
      totalPages: response.data.count.total,
      //params: response.data.params,
      categoryList: response.data.data,
      //filterParams: params
    }
  }
)

export const getMainCategoryList = createAsyncThunk(
  'mainCategory/getMainCategoryList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/mainCategory?pageSize=40', { params })
    return {
      // totalPages: response.data.count.total,
      // params: response.data.params,
      mainCategoryList: response.data.data,
      filterParams: params
    }
  }
)

export const getSubCategoryList = createAsyncThunk(
  'mainCategory/getSubCategoryList',
  async (params) => {
    const response = await axios.get(baseUrl() + `/mainCategory/${params}`)
    console.log("getSubCategoryList", response)
    return {
      subCategoryList: response.data.subCategories
    }
  }
)

export const getAllTopicList = createAsyncThunk(
  'mainCategory/getAllTopicList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/mainCategory', { params })
    return {
      params: response.data.params,
      AllTopicList: response.data.data
    }
  }
)

export const getTopicById = createAsyncThunk(
  'mainCategory/getTopicById',
  async ({ topicId }) => {
    const response = await axios.get(baseUrl() + `/mainCategory/${topicId}`)
    return {
      topicById: response?.data?.data[0]
    }
  }
)

export const getTopicListByTechnologyId = createAsyncThunk(
  'mainCategory/getTopicListByTechnologyId',
  async (id) => {
    const response = await axios.get(
      baseUrl() + `/mainCategory/all?technologyId=${id}`
    )

    return {
      // totalPages: response.data.count.total,
      // params: response.data.params,
      // categoryList: response.data.data
      alltopicList: response.data.data
    }
  }
)

export const addTopic = createAsyncThunk(
  'mainCategory/addTopic',
  async ({ payload, params }, { dispatch }) => {
    console.log("payload", payload)
    try {
      await axios.post(baseUrl() + '/mainCategory', payload)
      toast.success('Category added successfully')
      await dispatch(
        getCategoryList({
          params
        })
      )
      //return mainCategory
    } catch (error) {
      toast.error(error?.response?.data)
    }
  }
)
export const topicBulkUpload = createAsyncThunk(
  'mainCategory/topicBulkUpload',
  async ({ payload, params }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/mainCategory/topicUpload', payload)
      toast.success('Category Uploaded successfully')
      await dispatch(
        getCategoryList({
          params
        })
      )
      return true
      //return mainCategory
    } catch (error) {
      toast.error(error?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const updateTopic = createAsyncThunk(
  'mainCategory/updateTopic',
  async ({ id, payload, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/mainCategory/${id}`, payload)
      toast.success('Category Updated successfully')
      await dispatch(
        getCategoryList({
          params
        })
      )
      return { id }
    } catch (error) {
      toast.error(error?.response?.data)
    }
  }
)

export const deleteTopic = createAsyncThunk(
  'mainCategory/deleteTopic',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + '/mainCategory/' + id)
      toast.success('Category Deleted successfully')
      await dispatch(
        getCategoryList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data)
    }
  }
)

export const topicSlice = createSlice({
  name: 'mainCategory',
  initialState: {
    categoryList: [],
    alltopicList: [],
    topicById: [],
    AllTopicList: [],
    mainCategoryList: [],
    subCategoryList: [],
    selected: null,
    loader: true,
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    clearFilter: false,
    filterParams: {}
  },
  reducers: {
    selectMainCategory: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state, action) => {
      state.loader = action.payload
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    },
    selectClearFilter: (state) => {
      state.clearFilter = !state.clearFilter
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryList.fulfilled, (state, action) => {
        ; (state.categoryList = action.payload.categoryList),
          (state.totalPages = action.payload.totalPages)
        //state.filterParams = action?.payload?.filterParams
        state.loader = false
      })
      .addCase(getMainCategoryList.fulfilled, (state, action) => {
        ; (state.mainCategoryList = action.payload.mainCategoryList),
          //   (state.totalPages = action.payload.totalPages)
          // state.filterParams = action?.payload?.filterParams
          state.loader = false
      })
      .addCase(getSubCategoryList.fulfilled, (state, action) => {
        ; (state.subCategoryList = action.payload.subCategoryList),
          state.loader = false
      })
      .addCase(getCategoryList.pending, (state) => {
        state.loader = false
      })
      .addCase(getTopicById.fulfilled, (state, action) => {
        state.topicById = action.payload.topicById
      })
      .addCase(getAllTopicList.fulfilled, (state, action) => {
        state.AllTopicList = action.payload.AllTopicList
      })
  }
})

export const {
  selectMainCategory,
  setLoader,
  selectClearFilter,
  selectCurrentPage,
  selectRowsPerPage
} = topicSlice.actions

export default topicSlice.reducer
