// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getModuleDropdown = createAsyncThunk(
  'uploadPrivacyToc/getModuleDropdown',
  async () => {
    const response = await axios.get(baseUrl() + '/modules')
    return {
      moduleDropdown: response.data.data
    }
  }
)

export const getFileList = createAsyncThunk(
  'uploadPrivacyToc/getFileList',
  async (params) => {
    // dispatch(setLoader())
    const response = await axios.get(baseUrl() + '/privacydocumnets/uploadpdfile')
    return {
      // totalPages: response.data.count.total,
      fileList: response.data,
      loader: false
    }
  }
)

export const addFile = createAsyncThunk(
  'uploadPrivacyToc/addFile',
  async ({ formData, params }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/privacydocumnets/uploadpdfile`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      })
      toast.success('File added successfully')
      dispatch(getFileList())
      dispatch(setLoader(false))
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)


// export const addFile = createAsyncThunk(
//   'uploadPrivacyToc/addFile',
//   async (params) => {
//     try {
//       await axios.post(baseUrl() + '/modules', params)
//       toast.success('Module Added successfully')
//       return null
//     } catch (error) {
//       toast.error(error?.response?.data?.message)
//     }
//   }
// )

export const updateFile = createAsyncThunk(
  'uploadPrivacyToc/updateFile',
  async ({ id, moduleName }) => {
    try {
      await axios.put(baseUrl() + `/privacydocumnets/uploadpdfile/${id}`, {
        id,
        moduleName
      })
      toast.success('Module Updated successfully')
      return { id, moduleName }
    } catch (error) { }
  }
)

export const deleteFile = createAsyncThunk(
  'uploadPrivacyToc/deleteFile',
  async ({ id }, { dispatch }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + `/privacydocumnets/uploadpdfile/${id}`),
        toast.success('Module Deleted successfully')
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const uploadPrivacyToc = createSlice({
  name: 'uploadPrivacyToc',
  initialState: {
    moduleDropdown: [],
    fileList: [],
    selectMasterFile: [],
    loader: true,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0
  },
  reducers: {
    selectPrivacyToc: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },

  extraReducers: {
    [getModuleDropdown.fulfilled]: (state, action) => {
      state.moduleDropdown = action.payload.moduleDropdown
    },
    [getFileList.fulfilled]: (state, action) => {
      state.fileList = action.payload.fileList
      // state.totalPages = action.payload.totalPages
      state.loader = action.payload.loader
    }
  }
})

export const { selectPrivacyToc, setLoader, setRowsPerPage, setCurrentPage } =
  uploadPrivacyToc.actions

export default uploadPrivacyToc.reducer
