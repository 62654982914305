// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getTransactionsList = createAsyncThunk(
    'transactions/getTransactionsList',
    async (params) => {

        const response = await axios.get(baseUrl() + '/mcb/getAlldashboard', { params })
        return {
            totalPages: response.data.totalCount,
            params: response.data.params,
            data: response.data.data
        }
    }
)
export const mcbBulkUpload = createAsyncThunk(
    'transactions/mcbBulkUpload',
    async ({ formData, params }, { dispatch }) => {
        try {
            await axios.post(baseUrl() + '/mcb-csv/uploadfile', formData)
            toast.success('CSV Uploaded successfully')
            await dispatch(
                getTransactionsList({
                    params
                })
            )
            return true
        } catch (error) {
            let errorMessage = error?.response?.data?.error || 'An error occurred'
            if (error?.response?.data?.missingColumns) {
                errorMessage += ` Missing Columns: ${error?.response?.data?.missingColumns}`
            }
            toast.error(errorMessage)
            dispatch(setLoader(false))
            return false
        }
    }
)

export const allTransactionsList = createAsyncThunk(
    'transactions/allTransactionsList',
    async (params) => {
        const response = await axios.get(baseUrl() + '/transactions', { params })
        return {
            params: response.data.params,
            allTransactionsList: response.data.result
        }
    }
)

export const addTransactions = createAsyncThunk(
    'transactions/addTransactions',
    async ({ payload, params }, { dispatch, getState }) => {
        try {
            await axios.post(baseUrl() + '/transactions', payload)
            toast.success('Facility Added successfully')
            await dispatch(getTransactionsList({ params: params }))
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const updateTransactions = createAsyncThunk(
    'transactions/updateTransactions',
    async ({ id, name }, { dispatch, getState }) => {
        try {
            await axios.put(baseUrl() + `/transactions/${id}`, {
                id,
                name
            })
            toast.success('Facility Updated successfully')
            await dispatch(getTransactionsList())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const deleteTransactions = createAsyncThunk(
    'transactions/deleteTransactions',
    async ({ id }, { dispatch, getState }) => {
        try {
            await axios.delete(baseUrl() + `/transactions/${id}`),
                toast.success('Facility Deleted successfully')
            await dispatch(getTransactionsList())
            return true
        } catch (error) {
            toast.error(error?.response?.data?.result)
            return false
        }
    }
)

export const transactions = createSlice({
    name: 'transactions',
    initialState: {
        transactionsList: [],
        selectTransactions: [],
        allTransactionsList: [],
        reload: false,
        loader: false,
        rowsPerPage: 10,
        clearFilter: false,
        currentPage: 1,
        totalPages: 0,
        selected: null,
        params: {
            pageNo: 1,
            pageSize: 10
        }
    },
    reducers: {
        selectTransactions: (state, action) => {
            if (action.payload === null) {
                state.selected = null
            } else {
                state.selected = action.payload
            }
        },
        setLoader: (state) => {
            state.loader = !state.loader
        },
        setRowsPerPage: (state, action) => {
            state.rowsPerPage = action.payload
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload
        },
        selectClearFilter: (state, action) => {
            state.clearFilter = action.payload
        },
        setParams: (state, action) => {
            state.params = {
                ...state.params,
                ...action.payload
            }
        },
    },

    extraReducers: {
        [addTransactions.fulfilled]: (state, action) => {
            state.reload = !state.reload
        },
        [getTransactionsList.fulfilled]: (state, action) => {
            state.transactionsList = action.payload.data
            state.totalPages = action.payload.totalPages
            state.loader = false
        },
        [allTransactionsList.fulfilled]: (state, action) => {
            state.allTransactionsList = action.payload.allTransactionsList
        },
        [updateTransactions.fulfilled]: (state, action) => {
            state.reload = !state.reload
            state.loader = false
        },
    }
})

export const { selectTransactions, setLoader, setRowsPerPage, setCurrentPage, selectClearFilter, setParams } = transactions.actions

export default transactions.reducer
