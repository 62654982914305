// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import { baseUrl } from '../../../../../app.config'

export const getAssignTaskList = createAsyncThunk(
  'assignTask/getAssignTaskList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-task', { params })
    return {
      totalPages: response.data?.count?.total,
      params: response.data.params,
      assignTaskList: response.data.data
    }
  }
)

export const getTaskById = createAsyncThunk(
  'topic/getTaskById',
  async ({ taskId }) => {
    const response = await axios.get(baseUrl() + `/user-task/${taskId}`)
    return {
      taskById: response.data.data
    }
  }
)

export const getAllUserTaskHistory = createAsyncThunk(
  'assignTask/getAllUserTaskHistory',
  async (params) => {
    const response = await axios.get(baseUrl() + '/task-review', {
      params
    })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      allUserTaskHistory: response.data.data
    }
  }
)

export const updateUserTaskStatus = createAsyncThunk(
  'assignTopic/updateUserTaskStatus',
  async ({ id, userId, status }, { dispatch }) => {
    try {
      await axios.patch(baseUrl() + `/user-task/${id}`, { status })
      toast.success('User Task Updated successfully')

      return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const getDownloadTaskFiles = createAsyncThunk(
  'assignTask/getDownloadTaskFiles',
  async ({ fileName }) => {
    const config = {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'application/pdf'
      }
    }

    await axios
      .get(baseUrl() + `/downloadFile/${fileName}`, { config })
      .then((res) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([res.data], { type: 'application/pdf' })
        )
        //const link = document.createElement('a')
        window.open(fileURL)
        // link.href = fileURL
        //    link.setAttribute('download',"TEST.pdf")
        // document.body.appendChild(link)
        // link.click()
      })
      .catch((error) => console.log(error))
  }
)

export const addAssignTaskToUser = createAsyncThunk(
  'assignTask/addAssignTaskToUser',
  async (
    { userId, taskId, startDate, endDate, pageNo, pageSize, status },
    { dispatch }
  ) => {
    try {
      await axios.post(baseUrl() + '/user-task', {
        userId,
        taskId,
        startDate,
        endDate,
        status
      })
      toast.success('Task Assigned successfully')
      await dispatch(
        getAllTaskFilter({
          pageNo: 1,
          pageSize: 10,
          status
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateTaskReview = createAsyncThunk(
  'assignTask/updateTaskReview',
  async ({ userId, id, rating, status, feedback }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/user-task/${id}`, {
        rating,
        feedback,
        status,
        id,
        userId
      })
      toast.success('Review Updated successfully')
      await dispatch(
        getAllUserTaskHistory({
          userId
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const getAllTaskFilter = createAsyncThunk(
  'assignTask/getAllTaskFilter',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-task', {
      params
    })

    return {
      totalPages: response.data?.count?.total,
      params: response.data.params,
      filterTaskList: response.data.data,
      loader: false
    }
  }
)

export const updateAssignTask = createAsyncThunk(
  'assignTask/updateAssignTask',
  async (
    { id, updateData, pageNo, pageSize, search },
    { dispatch, getState }
  ) => {
    await axios.put(baseUrl() + `/tasks/${id}`, updateData)
    await dispatch(
      getAssignTaskList({
        pageNo,
        pageSize,
        search
      })
    )
    return { id }
  }
)

export const deleteAssignTask = createAsyncThunk(
  'assignTask/deleteAssignTask',
  async ({ id, status }, { dispatch }) => {
    dispatch(setLoader())
    await axios.delete(baseUrl() + `/user-task/${id}`)
    await dispatch(
      getAllTaskFilter({
        status
      })
    )

    return id
  }
)

export const AssignTaskSlice = createSlice({
  name: 'assignTask',
  initialState: {
    assignTaskList: [],
    filterTaskList: [],
    getTaskFiles: [],
    taskById: [],
    getAllUserTaskHistory: [],
    allUserTaskHistory: [],
    rowsPerPage: 10,
    currentPage: 1,
    status: '0',
    selected: null,
    loader: true
  },
  reducers: {
    selectAssignTask: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    selectCurrentPage: (state, action) => {
      if (action.payload === null) {
        state.currentPage = null
      } else {
        state.currentPage = action.payload
      }
    },
    selectRowsPerPage: (state, action) => {
      if (action.payload === null) {
        state.rowsPerPage = null
      } else {
        state.rowsPerPage = action.payload
      }
    },
    setStatus: (state, action) => {
      state.status = action.payload
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAssignTaskList.fulfilled, (state, action) => {
        ;(state.assignTaskList = action.payload.assignTaskList),
          (state.totalPages = action.payload.totalPages)
      })
      .addCase(addAssignTaskToUser.fulfilled, (state, action) => {
        state.toaster = 'Task added Successfully'
      })
      .addCase(getAllTaskFilter.fulfilled, (state, action) => {
        ;(state.filterTaskList = action.payload.filterTaskList),
          (state.loader = action.payload.loader),
          (state.totalPages = action.payload.totalPages)
      })
      .addCase(getTaskById.fulfilled, (state, action) => {
        state.taskById = action.payload.taskById
      })
      .addCase(getAllUserTaskHistory.fulfilled, (state, action) => {
        state.allUserTaskHistory = action.payload.allUserTaskHistory
        state.loader = false
      })
  }
})

export const {
  selectAssignTask,
  selectCurrentPage,
  selectRowsPerPage,
  setStatus,
  setLoader
} = AssignTaskSlice.actions

export default AssignTaskSlice.reducer
