// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getTechnologyList = createAsyncThunk(
  'technologies/getTechnologyList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/technologies', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      technologyList: response.data.data,
      loader: false
    }
  }
)

export const getAllTechnologyList = createAsyncThunk(
  'technologies/getAllTechnologyList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/technologies/all', {
      params
    })
    return {
      //totalPages: response.data.count.total,
      params: response.data.params,
      allTechnologyList: response.data.data
    }
  }
)

// export const getAllDeletedUsers = createAsyncThunk(
//   'getAllDeletedUsers',
//   async (params) => {
//     const response = await axios.get(baseUrl() + '/user/deleted', {
//       params
//     })
//     return {
//       //totalPages: response.data.count.total,
//       params: response.data.params,
//       allDeletedUsers: response.data.data
//     }
//   }
// )

export const getTechnologyById = createAsyncThunk(
  'technologies/getTechnologyById',
  async ({ id }) => {
    const response = await axios.get(baseUrl() + `/technologies/${id}`)
    return {
      data: response.data.data
    }
  }
)
export const addTechnology = createAsyncThunk(
  'technologies/addTechnology',
  async ({ categoryId, technologyName, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.post(baseUrl() + '/technologies', {
        categoryId,
        technologyName
      })
      toast.success('Technology Added successfully')
      // await dispatch(getTechnologyList(pageNo, pageSize))
      //return technologies
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateTechnology = createAsyncThunk(
  'technologies/updateTechnology',
  async (
    { technologyName, categoryId, pageNo, pageSize, id },
    { dispatch }
  ) => {
    try {
      await axios.put(baseUrl() + '/technologies/' + id, {
        technologyName,
        categoryId
      })
      toast.success('Technology Updated successfully')
      // await dispatch(getTechnologyList({ pageNo, pageSize }))
      return { technologyName, categoryId }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteTechnology = createAsyncThunk(
  'technologies/deleteTechnology',
  async ({ id, pageNo, pageSize }, { dispatch, getState }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + '/technologies/' + id, {
        id
      })
      toast.success('Technology Deleted successfully')
      await dispatch(getTechnologyList({ pageNo, pageSize }))
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const technologies = createSlice({
  name: 'technologies',
  initialState: {
    technologyList: [],
    allTechnologyList: [],
    technologyListById: [],
    selectedTechnology: null,
    totalPages: 0,
    loader: true,
    params: {
      pageNo: 1,
      pageSize: 10
    },
    selected: null
  },
  reducers: {
    selectTechnology: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTechnologyList.fulfilled, (state, action) => {
        ;(state.technologyList = action.payload.technologyList),
          (state.totalPages = action.payload.totalPages)
        state.loader = action.payload.loader
      })
      .addCase(getTechnologyList.pending, (state, action) => {
        state.loader = true
      })
      .addCase(getAllTechnologyList.fulfilled, (state, action) => {
        state.allTechnologyList = action.payload.allTechnologyList
      })
      .addCase(getTechnologyById.fulfilled, (state, action) => {
        state.technologyListById = action.payload.data
      })
  }
})

export const { selectTechnology, setLoader, setShowDeletedUser } =
  technologies.actions

export default technologies.reducer
