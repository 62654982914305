// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

import { baseUrl } from './../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getProjectList = createAsyncThunk(
  'projectMaster/getProjectList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/projects', { params })
    return {
      totalPages: response.data.count.total,
      params: response.data.params,
      projectMasterList: response.data.data
    }
  }
)

export const getAllProjectList = createAsyncThunk(
  'projectMaster/getAllProjectList',
  async () => {
    const response = await axios.get(baseUrl() + '/projects')
    return {
      AllProjectList: response.data.data
    }
  }
)

export const getProjectyId = createAsyncThunk(
  'projectMaster/getProjectyId',
  async ({ id }) => {
    const response = await axios.get(baseUrl() + `/projects/${id}`)
    return {
      projectById: response.data.data
    }
  }
)

export const addProject = createAsyncThunk(
  'projectMaster/addProject',
  async (
    { projectName, projectManegerName, startDate, endDate, pageNo, pageSize },
    { dispatch }
  ) => {
    try {
      await axios.post(baseUrl() + '/projects', {
        projectName,
        projectManegerName,
        startDate,
        endDate
      })
      toast.success('Project added successfully')
      await dispatch(
        getProjectList({
          pageNo,
          pageSize
        })
      )
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const updateProject = createAsyncThunk(
  'projectMaster/updateProject',
  async (
    {
      id,
      projectName,
      projectManegerName,
      startDate,
      endDate,
      pageNo,
      pageSize,
      search
    },
    { dispatch, getState }
  ) => {
    try {
      await axios.put(baseUrl() + `/projects/${id}`, {
        projectName,
        projectManegerName,
        startDate,
        endDate
      })
      toast.success('Project Updated successfully')
      await dispatch(
        getProjectList({
          pageNo,
          pageSize,
          search
        })
      )
      return { id }
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const deleteProject = createAsyncThunk(
  'projectMaster/deleteProject',
  async ({ id, pageNo, pageSize }, { dispatch }) => {
    try {
      await axios.delete(baseUrl() + '/projects/' + id)
      toast.success('Project Deleted successfully')
      await dispatch(
        getProjectList({
          pageNo,
          pageSize
        })
      )
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const ProjectMasterSlice = createSlice({
  name: 'projectMaster',
  initialState: {
    projectMasterList: [],
    AllProjectList: [],
    projectById: [],
    selected: null,
    totalPages: 0
  },
  reducers: {
    selectProject: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProjectList.fulfilled, (state, action) => {
        ;(state.projectMasterList = action.payload.projectMasterList),
          (state.totalPages = action.payload.totalPages)
      })
      .addCase(getAllProjectList.fulfilled, (state, action) => {
        state.AllProjectList = action.payload.AllProjectList
      })
      .addCase(getProjectyId.fulfilled, (state, action) => {
        state.projectById = action.payload.projectById
      })
  }
})

export const { selectProject } = ProjectMasterSlice.actions

export default ProjectMasterSlice.reducer
