// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../app.config'

// ** Axios Imports
import axios from 'axios'
import { initialState } from 'react-doc-viewer/build/state/reducer'
import toast from 'react-hot-toast'

export const getReviews = createAsyncThunk(
  'dashboard/getReviews',
  async (params) => {
    const response = await axios.get(baseUrl() + '/user-review/ratingsList', {
      params
    })
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getMaxTopics = createAsyncThunk(
  'dashboard/getMaxTopics',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user-topic/max-topics-covered-by-user',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const maxTopicsCoveredTech = createAsyncThunk(
  'dashboard/maxTopicsCoveredTech',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/technologies/maxTopicCovered',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const topResourcesTech = createAsyncThunk(
  'dashboard/topResourcesTech',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/user/get-top-user-ready-to-deploy',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const maxReviewMentor = createAsyncThunk(
  'dashboard/maxReviewMentor',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/reviewCountOfMentors',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)
export const getDashboardCount = createAsyncThunk(
  'dashboard/getDashboardCount',
  async () => {
    const response = await axios.get(
      baseUrl() + `/dashboard`)
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getCountLocations = createAsyncThunk(
  'dashboard/getCountLocations',
  async (params) => {
    const response = await axios.get(baseUrl() + '/dashboard/countbylocation',

      { params }
    )
    return {
      data: response.data.data.locationCounts ? response.data.data.locationCounts : []
    }
  }
)

export const getCountByMainCategories = createAsyncThunk(
  'dashboard/getCountByMainCategories',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/countbymaincat',
      { params }
    )
    return {
      data: response.data.data.mainCategoriesCounts ? response.data.data.mainCategoriesCounts : []
    }
  }
)

export const getUserCountTechStatus = createAsyncThunk(
  'dashboard/getUserCountTechStatus',
  async (params) => {
    const response = await axios.get(
      baseUrl() + '/dashboard/userCountTechStatus',
      { params }
    )
    return {
      data: response.data.data ? response.data.data : []
    }
  }
)

export const getLatestCompany = createAsyncThunk('dashboard/getLatestCompany', async (params) => {
  const response = await axios.get(baseUrl() + '/dashboard/listbydates', { params })

  return {
    data: response?.data?.data,
    totalPages: response?.data?.count?.total,
    currentPage: response?.data?.count?.pageNumber,
    rowsPerPage: response?.data?.count?.pageSize,
    params: { ...params }
  }
}
)

export const getMentorReviewList = createAsyncThunk(
  'user-mentor/reviewList',
  async (id) => {
    try {
      const response = await axios.get(
        baseUrl() + `/user-mentor/reviewList/${id}`
      )
      console.log('response :>> ', response)
      return {
        data: response?.data?.data ? response?.data?.data : []
      }
    } catch (error) {
      console.log(
        'error?.response?.data?.message :>> ',
        error?.response?.data?.message
      )
      toast.error(error?.response?.data?.message)
    }
  }
)

export const dashboard = createSlice({
  name: 'dashboard',
  initialState: {
    countByLocations: [],
    countByMainCategories: [],
    userCountTechStatus: [],
    latestData: [],
    resourceList: [],
    comapanyStatusCount: [],
    mentorReviewList: [],
    maxCountMentor: [],
    currentPage: 1,
    rowsPerPage: 10,
    totalPages: 0,
    reload: false,
    status: null,
    clearFilter: false,
    select: null,
    loader: true,
    reviewTableLoader: true,
    params: {
      pageNo: 1,
      pageSize: 10
    },
  },
  reducers: {
    selectClearFilter: (state) => {
      state.clearFilter = !state.clearFilter
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    clearMentorReviewList: (state) => {
      state.mentorReviewList = []
    },
    setParams: (state, action) => {
      state.params = {
        ...state.params,
        ...action.payload
      }
    },
  },

  extraReducers: {
    [getCountLocations.fulfilled]: (state, action) => {
      state.countByLocations = action.payload.data
    },
    [getCountByMainCategories.fulfilled]: (state, action) => {
      state.countByMainCategories = action.payload.data
    },
    [getUserCountTechStatus.fulfilled]: (state, action) => {
      state.userCountTechStatus = action.payload.data
    },
    [getLatestCompany.fulfilled]: (state, action) => {
      ; (state.latestData = action.payload.data),
        (state.loader = false),
        (state.totalPages = action.payload.totalPages),
        (state.params = action.payload.params),
        (state.currentPage = action.payload.currentPage),
        (state.rowsPerPage = action.payload.rowsPerPage)
    },
    [getLatestCompany.pending]: (state) => {
      state.loader = true
    },
    [getReviews.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [getMaxTopics.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [maxTopicsCoveredTech.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [topResourcesTech.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [maxReviewMentor.fulfilled]: (state, action) => {
      state.resourceList = action.payload.data
      state.loader = false
    },
    [getDashboardCount.fulfilled]: (state, action) => {
      state.comapanyStatusCount = action.payload.data
      state.loader = false
    },
    [getMentorReviewList.fulfilled]: (state, action) => {
      state.mentorReviewList = action?.payload?.data
      state.reviewTableLoader = false
    },
    [getMentorReviewList.pending]: (state) => {
      state.reviewTableLoader = true
    },
    [getMentorReviewList.rejected]: (state) => {
      state.reviewTableLoader = false
    }
  }
})

export const {
  selectDashboard,
  selectClearFilter,
  setLoader,
  clearMentorReviewList,
  setParams,

} = dashboard.actions

export default dashboard.reducer
