// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { baseUrl } from '../../../../../app.config'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getModuleDropdown = createAsyncThunk(
  'externalLinks/getModuleDropdown',
  async () => {
    const response = await axios.get(baseUrl() + '/modules')
    return {
      moduleDropdown: response.data.data
    }
  }
)

export const getAllUrlList = createAsyncThunk(
  'externalLinks/getAllUrlList',
  async (params) => {
    const response = await axios.get(baseUrl() + '/extlinks/getall')

    return {
      // totalPages: response.data.count.total,
      urlList: response.data.result,
      loader: false
    }
  }
)

export const addUrlData = createAsyncThunk(
  'externalLinks/addUrlData',
  async ({ payload, params }, { dispatch }) => {


    try {
      await axios.post(baseUrl() + '/extlinks/new', payload)
      toast.success('Url Saved successfully')
      await dispatch(getAllUrlList())
      return true
    } catch (e) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)


// export const addUrlData = createAsyncThunk(
//   'externalLinks/addUrlData',
//   async (params) => {
//     try {
//       await axios.post(baseUrl() + '/modules', params)
//       toast.success('Module Added successfully')
//       return null
//     } catch (error) {
//       toast.error(error?.response?.data?.message)
//     }
//   }
// )

export const updateUrlData = createAsyncThunk(
  'externalLinks/updateUrlData',
  async ({ payload, id }, { dispatch }) => {
    try {
      await axios.put(baseUrl() + `/extlinks/${id}`, payload)
      toast.success('Url Updated successfully')
      await dispatch(getAllUrlList())
      return true
    } catch (error) {
      toast.error(e?.response?.data?.message)
      dispatch(setLoader(false))
      return false
    }
  }
)

export const deleteUrl = createAsyncThunk(
  'externalLinks/deleteUrl',
  async ({ id }, { dispatch }) => {
    try {
      dispatch(setLoader())
      await axios.delete(baseUrl() + `/extlinks/${id}`),
        toast.success('Url Deleted successfully')
      return id
    } catch (error) {
      toast.error(error?.response?.data?.message)
    }
  }
)

export const externalLinks = createSlice({
  name: 'externalLinks',
  initialState: {
    moduleDropdown: [],
    urlList: [],
    selectMasterFile: [],
    loader: true,
    rowsPerPage: 10,
    currentPage: 1,
    totalPages: 0
  },
  reducers: {
    selectExtLinks: (state, action) => {
      if (action.payload === null) {
        state.selected = null
      } else {
        state.selected = action.payload
      }
    },
    setLoader: (state) => {
      state.loader = !state.loader
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    }
  },

  extraReducers: {
    [getModuleDropdown.fulfilled]: (state, action) => {
      state.moduleDropdown = action.payload.moduleDropdown
    },
    [getAllUrlList.fulfilled]: (state, action) => {
      state.urlList = action.payload.urlList
      // state.totalPages = action.payload.totalPages
      state.loader = action.payload.loader
    }
  }
})

export const { selectExtLinks, setLoader, setRowsPerPage, setCurrentPage } =
  externalLinks.actions

export default externalLinks.reducer
